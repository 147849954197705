export default [
  {
    path: '/overview-monitoring',
    name: 'overview-monitoring',
    component: () => import('@/views/pages/komcards/overview/List.vue'),
    meta: {
      resource: 'OverviewMonitoring',
      action: 'manage',
    },
  },
]
